import React from 'react'
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

// @ts-ignore
import logo from './logo.svg';
import './App.css';
import ComingSoon from "./pages/coming_soon/comingSoon";

const App = () => {
    return (
        <div className="App">
            <Router>
                <br></br>
                <Switch>
                    <Route component={ComingSoon}/>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
