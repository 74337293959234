import React from 'react'

const ComingSoon = () => {
    return (
            <div className="coming-soon">
                <h1 className="coming-soon-text" >Coming Soon!</h1>
            </div>
    )
}

export default ComingSoon;
